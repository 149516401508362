import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取卡友權益詳情
export const cardFriendBenefits=(params)=>{
    return getRequest(API.cardFriendBenefits,params);
}
// 編輯卡友權益
export const cardFriendBenefitsEdit=(params)=>{
    return postRequest(API.cardFriendBenefitsEdit,params);
}
// 獲取會員權益詳情
export const memberBenefits=(params)=>{
    return getRequest(API.memberBenefits,params);
}
// 編輯會員權益
export const memberBenefitsEdit=(params)=>{
    return postRequest(API.memberBenefitsEdit,params);
}