<template>
  <div class="mainwrap privactedit" v-loading="loading">
    <div class="title">卡友權益編輯</div>
    <div class="formwrap">
      <el-form
        :model="privactForm"
        ref="bannerForm"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="是否启用" prop="isEnable">
          <el-switch v-model="isEnable" :disabled="permissionType !== 2"></el-switch>
        </el-form-item>
        <el-form-item label="卡友權益">
          <div class="privact-editor">
            <editor
              @getEditorHtml="getEditorHtml"
              :htmlData="myhtml"
              v-if="showEditor"
            ></editor>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap" v-if="permissionType === 2">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <!--        <el-button @click="cancel">返回</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
// 引入富文本
import editor from "@/components/Editor.vue";
import * as api from "./api";
import { privacyPolicyEdit } from "./api";
import { getStore} from '@/utils/storage';
export default {
  name: "privacyEdit",
  components: {
    editor,
  },
  data() {
    return {
      loading: false,
      statusOptions: [],
      privactForm: {
        isActive: "",
      },
      myhtml: "",
      showEditor: false,
      isEnable: false,
      permissionType: -1
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      let dataForm = {
        content: this.myhtml,
        isEnable: this.isEnable,
      };
      api.cardFriendBenefitsEdit(dataForm).then((res) => {
        if (res.systemCode === 200) {
          this.$message.success("提交成功");
          this.getDetail();
        } else {
          // this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    getEditorHtml(data) {
      this.myhtml = data.html;
    },
    getDetail() {
      this.loading = true;
      api.cardFriendBenefits({}).then((res) => {
        if (res.systemCode === 200) {
          this.myhtml = res.data.content;
          this.isEnable = res.data.isEnable;
          this.showEditor = true;
        }
        this.loading = false;
      });
    },
  },
  created() {
    this.getDetail();
    this.permissionType = parseInt(getStore("permissionType"));
  },
};
</script>

<style lang="scss" scoped>
.privactedit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
  }
}
</style>
<style lang="scss">
.privact-editor {
  width: 680px;
  height: 550px;
  .myeditor {
    height: 550px;
    .ql-container {
      height: 500px;
    }
  }
}
</style>
