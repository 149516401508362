import { getRequest,postFormReq,deleteRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const questionnaireList=(params)=>{
    return getRequest(API.questionnaireList,params);
}
export const questionnaireDelete=(params)=>{
    return deleteRequest(API.questionnaireList+'/'+params);
}

// 品牌列表
export const getBrandBieList=(params)=>{
  return getRequest(API.brandBieList,params);
}
// 店別列表
export const getCounterList=(params)=>{
  return getRequest(API.shopList,params);
}
// 上傳圖片
export const uploadImage=(params)=>{
  return postFormReq(API.uploadImage,params);
}
// 獲取抵用券
export const getCouponList=(params)=>{
  return getRequest(API.questionnaireCoupon,params);
}
// 新增編輯問卷
export const editQuestionnaire=(params)=>{
  return postRequest(API.questionnaireEdit, params);
}
// 獲取問卷詳情
export const questionnaireDetail=(params)=>{
  return getRequest(API.questionnaireList+'/'+params);
}

