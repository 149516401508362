<template>
  <div class="mainwrap bannerlist" v-loading="loading">
    <div class="title">
      貢獻度分析
    </div>
    <div class="searchform">
      <el-form
        label-width="100px"
        :inline="true"
        :rules="rules"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="查詢範圍：">
          <el-date-picker
            :editable="false"
            v-model="searchForm.Date"
            value-format="yyyy-MM-dd"
            type="daterange" 
            start-placeholder="開始時間"
            end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        
        <el-form-item label="品牌：" prop="Brand">
          <!-- <el-input v-model="searchForm.Name" placeholder="請輸入名稱"></el-input> -->
          <el-select v-model="searchForm.Brand" filterable multiple @change="selectChange" placeholder="请选择品牌">
            <el-option
              v-for="item in brandOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Top：" prop="Top">
          <el-input type="number" @input="changeValue" v-model="searchForm.Top" placeholder="請輸入Top"></el-input>
        </el-form-item>

        <el-form-item label="維度：">
          <el-select v-model="searchForm.Dimension" placeholder="请选择維度">
            <el-option
              v-for="item in dimensionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
          <el-button type="success" @click="downLoad" :disabled="disabledDown">導出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <div class="xtitle">
        <div class="mar">
          概覽
        </div>
        <div>
          <div class="addbtn2">
            <el-button  type="success" @click="dialogTableVisible2 = true">消費次數</el-button>
          </div>
          <div class="addbtn2">
            <el-button  type="success" @click="dialogTableVisible1 = true">消費金額</el-button>
          </div>
          <div class="addbtn2">
            <el-button  type="success" @click="dialogTableVisible = true">概覽明細</el-button>
          </div>
        </div>
      </div>
      <el-table :data="allChartData.overview" style="width: 100%" stripe>
        <el-table-column prop="totalAmount" label="統計來源之消費總金額"> </el-table-column>
        <el-table-column prop="totalNum" label="統計來源之消費總次數"> </el-table-column>
        <el-table-column prop="totalMember" label="統計來源之會員總人數"> </el-table-column>
        <!-- <el-table-column label="時間">
          <template slot-scope="scope">
            {{scope.row.startTime}} 至 {{scope.row.endTime}}
          </template>
        </el-table-column>
        <el-table-column label="狀態">
          <template slot-scope="scope">
            {{scope.row.isActive==="是"? "已發佈" :"未發佈"}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <el-button type="danger" plain>删除</el-button>
            <div class="delbtn">
              <el-popconfirm
              confirm-button-text='確定'
              cancel-button-text='取消'
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="確定刪除？"
              @confirm="delBanner(scope.row.id)"
            >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <div class="tablewrap">
      <div class="xtitle">
        <div class="mar">
          品牌
        </div>
      </div>
      <el-table :data="allChartData.brands" style="width: 100%" stripe>
        <el-table-column prop="name" label="品牌"> </el-table-column>
        <el-table-column prop="num" :label="'Top'+ TopChange+'消費次數總計'"> </el-table-column>
        <el-table-column prop="amount" :label="'Top' +TopChange+'消費金額總計'"> </el-table-column>
      </el-table>
    </div>
    
      <div ref="chart" id="chart"></div>
      <div ref="chart" id="chart1"></div>
    
    <div class="tablewrap">
      <div class="xtitle">
        <div class="mar">
          性别
        </div>
      </div>
      <el-table :data="allChartData.sex" style="width: 100%" stripe>
        <el-table-column prop="name" label=""> </el-table-column>
        <el-table-column label="性別比">
          <template slot-scope="scope">
            {{ scope.row.num + '%' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
      <div ref="chart" id="chart2"></div>

    <div class="tablewrap">
      <div class="xtitle">
        <div class="mar">
          年齡
        </div>
      </div>
      <el-table :data="allChartData.age" style="width: 100%" stripe>
        <el-table-column prop="name" label=""> </el-table-column>
        <el-table-column label="年齡比"> 
          <template slot-scope="scope">
            {{ scope.row.num + '%' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
      <div ref="chart" id="chart3"></div>

    <div class="tablewrap">
      <div class="xtitle">
        <div class="mar">
          {{this.DimensionChange==1?'消費次數':'消費金額' }} TOP {{' '+TopChange}}各年齡於各品牌消費金額
        </div>
      </div>
      <el-table :data="allChartData.brandAmount" style="width: 100%" stripe>
        <el-table-column prop="brand" label=""> </el-table-column>
        <el-table-column prop="age1" label="18歲以下"> </el-table-column>
        <el-table-column prop="age2" label="18-25歲"> </el-table-column>
        <el-table-column prop="age3" label="26-35歲"> </el-table-column>
        <el-table-column prop="age4" label="36-45歲"> </el-table-column>
        <el-table-column prop="age5" label="46-55歲"> </el-table-column>
        <el-table-column prop="age6" label="55歲以上"> </el-table-column>
      </el-table>
    </div>
      <div ref="chart" id="chart4"></div>

    <div class="tablewrap">
      <div class="xtitle">
        <div class="mar">
          {{this.DimensionChange==1?'消費次數':'消費金額' }} TOP {{' '+TopChange}}各年齡於各品牌消費次數
        </div>
      </div>
      <el-table :data="allChartData.brandNum" style="width: 100%" stripe>
        <el-table-column prop="brand" label=""> </el-table-column>
        <el-table-column prop="age1" label="18歲以下"> </el-table-column>
        <el-table-column prop="age2" label="18-25歲"> </el-table-column>
        <el-table-column prop="age3" label="26-35歲"> </el-table-column>
        <el-table-column prop="age4" label="36-45歲"> </el-table-column>
        <el-table-column prop="age5" label="46-55歲"> </el-table-column>
        <el-table-column prop="age6" label="55歲以上"> </el-table-column>
      </el-table>
    </div>
      <div ref="chart" id="chart5"></div>

      <el-dialog title="概覽明細" :visible.sync="dialogTableVisible">
        <el-table :data="allChartData.details" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
          <el-table-column prop="name" label="姓名" width="100"> </el-table-column>
          <el-table-column prop="sex" label="性別" width="77"> </el-table-column>
          <el-table-column prop="age" label="年齡" width="50"> </el-table-column>
          <el-table-column prop="brand" label="品牌"></el-table-column>
          <el-table-column prop="amount" label="金額" width="80"> </el-table-column>
          <el-table-column prop="totalAmount" label="會員消費總金額" width="80"> </el-table-column>
          <el-table-column prop="totalNum" label="會員消費總次數" width="70"> </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog title="消費金額" :visible.sync="dialogTableVisible1">
        <el-table :data="allChartData.paymentAmount">
          <el-table-column property="name" :label="'消費金額TOP'+ TopChange"></el-table-column>
          <el-table-column property="sex" label="性別"></el-table-column>
          <el-table-column property="amount" label="消費金額" width="150"></el-table-column>
          <el-table-column property="age" label="年齡"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog title="消費次數" :visible.sync="dialogTableVisible2">
        <el-table :data="allChartData.paymentNum">
          <el-table-column property="name" :label="'消費次數TOP'+ TopChange"></el-table-column>
          <el-table-column property="num" label="消費次數" width="200"></el-table-column>
          <el-table-column property="age" label="年齡"></el-table-column>
        </el-table>
      </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import * as echarts from 'echarts'
export default {
  name: 'reportForm',
  components: {
    // 'v-chart': ECharts
  },
  
  data() {
    return {
      loading:false,
      searchForm: {
        Brand: "",
        Date: "",
        Top: 100,
        Dimension: 1,
      },
      brandOptions: [],
      dimensionOptions: [
        {
          value: 1,
          label: "次数"
        },
        {
          value: 2,
          label: "金额"
        },
      ],
      rules: {
        Brand: [{ required: true, message: "請選擇品牌", trigger: "change" }],
        Top: [{ required: true, message: "請輸入Top", trigger: "blur" }],
      },
      allChartData:{},
      dialogTableVisible:false,
      dialogTableVisible1:false,
      dialogTableVisible2:false,

      TopChange:0,
      DimensionChange:1,
      details:[],


      myChart:'',
      myChart1:'',
      myChart2:'',
      myChart3:'',
      myChart4:'',
      myChart5:'',

      ageList:['18歲以下','18-25歲','26-35歲','36-45歲','46-55歲','55歲以上'],
      disabledDown: true
    };
  },
  methods: {
    downLoad(){
      this.disabledDown=true;
      let params={
          StartTime:this.searchForm.Date?this.searchForm.Date[0]:'',
          EndTime:this.searchForm.Date?this.searchForm.Date[1]:'',
          Brands:this.searchForm.Brand,
          Top:this.searchForm.Top,
          DimensionType:this.searchForm.Dimension
      }
      for (let key in params){
          if(params[key]===""){
              delete params[key];
          }
      }
      this.loading=true;
      api.exportDetailsReport(params).then(res=>{
        if(res.systemCode===200){
            window.open(res.data,"_blank")
        }
        this.disabledDown=false;
        this.loading=false;
      })
    },
    onSearch(){
      this.TopChange= this.searchForm.Top
      this.contributionAnalysis();
    },
    reSet(){
      this.searchForm={
        Brand: [],
        Date: "",
        Top: 100,
        Dimension: 1,
      }
      // this.searchForm.Brand.push(this.brandOptions[0].value)
      this.getCouponList()
    },
    changeValue(value){
      this.searchForm.Top = /[1-9]{1}[0-9]*$/.test(parseInt(value)) ? String(parseInt(value)).replace(".", "") : ""
    },
    getCouponList(){
      this.loading = true
      api.tableBrandList().then(res=>{
        if(res.systemCode===200){
          this.loading = false
          this.multiple = false
          this.brandOptions = res.data.map(row=>({
            label:row.value,
            value:row.key,
            disabled:true
          }))
          for(let item of this.brandOptions){
            if(item.value == 0){
              item.disabled = false
            }
          }
          this.searchForm.Brand.push(this.brandOptions[0].value)
          this.$refs["searchForm"].clearValidate('Brand')
          // this.contributionAnalysis();
        }
      })
      
    },
    selectChange(val){
      if(this.searchForm.Brand.length==0){
        for(let item of this.brandOptions){
            item.disabled = false
        }
      }else if(!this.searchForm.Brand.includes(0)){
        for(let item of this.brandOptions){
          if(item.value == 0){
            item.disabled = true
          }else{
            item.disabled = false
          }
        }
      }else{
        for(let item of this.brandOptions){
          if(item.value == 0){
            item.disabled = false
          }else{
            item.disabled = true
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      if (columnIndex==0 || columnIndex==1 || columnIndex==2 || columnIndex == 5 || columnIndex==6 ) {
        if(rowIndex === 0 || row.memberId != this.allChartData.details[rowIndex-1].memberId){
          let rowspan = 0;
          this.allChartData.details.forEach(element => {
            if(element.memberId == row.memberId){
              rowspan++
            } 
          })
          return [rowspan, 1];
        } else {
					return [0, 0];
				}
      }
    },
    contributionAnalysis() {
      this.$refs["searchForm"].validate(valid => {
        if (valid) {
          this.loading = true
          api.contributionAnalysis({
            StartTime:this.searchForm.Date?this.searchForm.Date[0]:'',
            EndTime:this.searchForm.Date?this.searchForm.Date[1]:'',
            Brands:this.searchForm.Brand,
            Top:this.searchForm.Top,
            DimensionType:this.searchForm.Dimension
          }).then(res=>{
            if(res.systemCode===200){
              this.disabledDown=false;
              this.loading = false
              let overview = res.data.overview
              this.allChartData = res.data
              this.allChartData.overview = []
              this.allChartData.overview.push(overview)
              this.TopChange = this.searchForm.Top
              this.DimensionChange = this.searchForm.Dimension
              this.myChart.setOption( {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: (item) => {
                    return  `${item[0].marker}<span>${item[0].axisValueLabel}</span>  <span>${item[0].data}</span>`
                  }
                },
                title: {
                  text: 'TOP'+this.TopChange+'各品牌消費次數總計',
                  left:'center'
                },
                xAxis: {
                  type: 'category',
                  data: res.data.brands.map(item=>item.name)
                },
                yAxis: {
                  type: 'value'
                },
                series: [
                  {
                    data: res.data.brands.map(item=>item.num),
                    type: 'bar',
                  }
                ]
              },true)
              this.myChart1.setOption( {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: (item) => {
                    return  `${item[0].marker}<span>${item[0].axisValueLabel}</span>  <span>${item[0].data }</span>`
                  }
                },
                title: {
                  text: 'TOP'+this.TopChange+'各品牌消費金額總計',
                  left:'center'
                },
                xAxis: {
                  type: 'category',
                  data: res.data.brands.map(item=>item.name)
                },
                yAxis: {
                  type: 'value'
                },
                series: [
                  {
                    data: res.data.brands.map(item=>item.amount),
                    type: 'bar',
                  }
                ]
              },true)
              this.myChart2.setOption( {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: (item) => {
                    return  `${item[0].marker}<span>${item[0].axisValueLabel}</span>  <span>${item[0].data  + '%'}</span>`
                  }
                },
                title: {
                  text: 'TOP'+this.TopChange+'性別比',
                  left:'center'
                },
                xAxis: {
                  type: 'category',
                  data: res.data.sex.map(item=>item.name)
                },
                yAxis: {
                  type: 'value'
                },
                series: [
                  {
                    data: res.data.sex.map(item=>item.num),
                    type: 'bar',
                  }
                ]
              },true)
              this.myChart3.setOption( {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: (item) => {
                    return  `${item[0].marker}<span>${item[0].axisValueLabel}</span>  <span>${item[0].data + '%'}</span>`
                  }
                },
                title: {
                  text: 'TOP'+this.TopChange+'年齡比',
                  left:'center'
                },
                xAxis: {
                  type: 'category',
                  data: res.data.age.map(item=>item.name)
                },
                yAxis: {
                  type: 'value'
                },
                series: [
                  {
                    data: res.data.age.map(item=>item.num),
                    type: 'bar',
                  }
                ]
              },true)
              let brandList = []
              for(let item of res.data.brandAmount){
                brandList.push(item.brand)
              }
              this.myChart4.setOption( {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                legend:{
                  data:brandList,
                  top:'bottom'
                },
                title: {
                  text: (this.searchForm.Dimension==1?'消費次數':'消費金額')+'TOP'+this.TopChange+'各年龄属于各品牌消费金额',
                  left:'center'
                },
                xAxis: {
                  type: 'category',
                  data: this.ageList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.data.brandAmount.map((item,index)=>{
                  return{
                    name:brandList[index],
                    data: [item.age1,item.age2,item.age3,item.age4,item.age5,item.age6],
                    type:'bar',
                  }
                })
              },true)
                    
              let brandList1 = []
              for(let item of res.data.brandNum){
                brandList1.push(item.brand)
              }
              this.myChart5.setOption( {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                },
                grid: {
                  left: 100
                },
                legend:{
                  data:brandList1,
                  top:'bottom'
                },
                title: {
                  text: (this.searchForm.Dimension==1?'消費次數':'消費金額')+'TOP'+this.TopChange+'各年龄属于各品牌消费次數',
                  left:'center'
                },
                xAxis: {
                  type: 'category',
                  data: this.ageList
                },
                yAxis: {
                  type: 'value'
                },
                series: res.data.brandNum.map((item,index)=>{
                  return{
                    name:brandList1[index],
                    data: [item.age1,item.age2,item.age3,item.age4,item.age5,item.age6],
                    type:'bar',
                  }
                })
              },true)
            }
          })
        }
      })
    },
  },
  created(){
    this.getCouponList()
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById("chart"))
    this.myChart.setOption({})
    this.myChart1 = echarts.init(document.getElementById("chart1"))
    this.myChart1.setOption({})
    this.myChart2 = echarts.init(document.getElementById("chart2"))
    this.myChart2.setOption({})
    this.myChart3 = echarts.init(document.getElementById("chart3"))
    this.myChart3.setOption({})
    this.myChart4 = echarts.init(document.getElementById("chart4"))
    this.myChart4.setOption({})
    this.myChart5 = echarts.init(document.getElementById("chart5"))
    this.myChart5.setOption({})
  }
};
</script>

<style lang="scss" scoped>
.bannerlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
  .xtitle {
    width: 100%;
    padding: 15px 0; 
    display: flex;
    justify-content: space-between;
  }
  .mar {
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .addbtn2 {
    width: 100px;
    float: right;
  }
  .echarts {
    width: 100%;
    height: 100%;
  }
  // #chart,#chart1 {
  //   width: 50%;
  //   height: 400px;
  //   display: inline-block;
  //   margin-top: 20px;
  // }
  #chart,#chart1,#chart2,#chart3,#chart4,#chart5{
    width: 100%;
    height: 400px;
    display: inline-block;
    margin-top: 20px;

  }
}
</style>

