import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"


// 匯出優惠券明細
export const contributionAnalysis=(params)=>{
  return postRequest(API.contributionAnalysis,params);
}
// 匯出優惠券明細
export const tableBrandList=()=>{
    return getRequest(API.tableBrandList);
}

// 匯出報表分析
export const exportDetailsReport=(params)=>{
    return postRequest(API.exportDetailsReport,params);
}
