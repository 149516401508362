<template>
  <div class="mainwrap restauranlist" v-loading="loading">
    <div class="title">
      問卷資訊
      <div class="addbtn" v-if="permissionType === 2">
        <el-button type="success" @click="handleEdit('')">新增</el-button>
      </div>
    </div>

    <div class="searchform">
      <el-form
        label-width="100px"
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="問卷名稱：">
          <el-input
            v-model="searchForm.Name"
            placeholder="请輸入問卷名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="查詢範圍：">
          <el-date-picker
            :editable="false"
            v-model="searchForm.Date"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="開始時間"
            end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="name" label="問卷名稱"> </el-table-column>
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="counter" label="店別"> </el-table-column>
        <el-table-column prop="paymentAmount" label="消費金額">
        </el-table-column>
        <el-table-column prop="isEnable" label="是否啓用"> </el-table-column>
        <el-table-column prop="startTime" label="開始時間"></el-table-column>
        <el-table-column prop="endTime" label="結束時間"></el-table-column>
        <el-table-column prop="sendCount" label="發送問卷數"></el-table-column>
        <el-table-column label="操作" width="220" v-if="permissionType === 2">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <div class="delbtn">
              <el-popconfirm
                confirm-button-text="確定"
                cancel-button-text="取消"
                confirm-button-type="danger"
                icon="el-icon-info"
                icon-color="red"
                title="確定刪除？"
                @confirm="deleteCounter(scope.row.id)"
              >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';

export default {
  name: "restaurantList",
  data() {
    return {
      loading: false,
      isSinglePage: false,
      currentPage: 1,
      tableDataTotal: 0,
      searchForm: {
        Name: "",
        Date: [],
        sorting: "",
        maxResultCount: 10,
      },
      options: [],
      tableData: [],
      permissionType: -1
    };
  },
  methods: {
    reSet() {
      this.searchForm = {
        Name: "",
        Date: [],
        sorting: "",
        maxResultCount: 10,
      };
      this.currentPage = 1;
      this.getList();
    },
    //编辑新增跳转
    handleEdit(item) {
      this.$router.push({
        path: "/cms/questionnaireManagementEdit",
        query: item
          ? {
              type: "edit",
              id: item.id,
            }
          : {
              type: "create",
            },
      });
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    // 查詢
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      console.log(this.searchForm.Date);
      api
        .questionnaireList({
          StartTime: this.searchForm.Date[0] ? this.searchForm.Date[0] : "",
          EndTime: this.searchForm.Date[1] ? this.searchForm.Date[1] : "",
          Name: this.searchForm.Name,
          Sorting: this.searchForm.sorting,
          SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
          MaxResultCount: this.searchForm.maxResultCount,
        })
        .then((res) => {
          if (res.systemCode === 200) {
            this.tableData = res.data.items;
            this.tableDataTotal = res.data.totalCount;
          }
          this.loading = false;
        });
    },
    // 刪除
    deleteCounter(id) {
      api.questionnaireDelete(id).then((res) => {
        if (res.systemCode === 200) {
          this.$message.success("刪除成功");
          //如果刪除項为當前頁最后一项
          if (this.tableData.length <= 1) {
            this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
          }
          this.getList();
        }
      });
    },
  },
  created() {
    this.getList();
    this.permissionType = parseInt(getStore("permissionType"));
  },
};
</script>

<style lang="scss" scoped>
.restauranlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 150px;
    float: right;
  }

  .delbtn {
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
